import * as React from "react"
import { getExcerpt } from "../excerptHelper"
import { PostListItem } from "./PostListItem"

export const PostList: React.FC<{ children: NonNullable<React.ReactNode> }> = (
  props
) => (
  <div className="oneColumn">
    <ul>{props.children}</ul>
  </div>
)

export const PostListForMDX: React.FC<{
  posts: Record<string, any>[]
}> = (props) => (
  <PostList>
    {props.posts.map(({ node }) => {
      return (
        <PostListItem
          title={node.frontmatter.title || node.fields.slug}
          subtitle={node.frontmatter.byline || node.frontmatter.date}
          link={node.wordCount?.words > 0 ? node.fields.slug : undefined}
          key={node.id}
          id={node.fields.slug}
          audio={node.frontmatter.audio}
          unpublished={node.frontmatter.published === false}
        >
          <p
            dangerouslySetInnerHTML={{
              __html: getExcerpt(node, true) || node.frontmatter.description,
            }}
          />
        </PostListItem>
      )
    })}
  </PostList>
)
