import * as React from "react"
import { Link } from "gatsby"
import { Unpublished } from "./Unpublished"
import { Audio } from "./Markdown"

export const PostListItem: React.FC<{
  id: string
  link?: string
  title: string
  subtitle?: string
  icon?: String
  audio?: string
  unpublished?: boolean
  children?: React.ReactNode
}> = (props) => {
  return (
    <li className="n-vspacing-large">
      <article key={props.id}>
        <PostLink link={props.link}>
          <header className="n-spacing-bottom-tiny">
            <h2>
              {props.icon ? props.icon + " " : undefined}
              <span className={props.link ? "g-link" : ""}>{props.title}</span>
            </h2>

            {props.subtitle && (
              <small className="n-type-smallest n-color-mid">
                {props.subtitle}
                {props.unpublished && <Unpublished />}
              </small>
            )}
          </header>
          <section>{props.children}</section>
        </PostLink>
        {props.audio && (
          <Audio controls className="n-spacing-top-small" preload="none">
            <source src={props.audio} type="audio/mpeg" />
          </Audio>
        )}
      </article>
    </li>
  )
}
const PostLink: React.FC<{ link?: string; children?: React.ReactNode }> = (
  props
) =>
  props.link ? (
    <Link to={props.link} tabIndex={1} className="post-link">
      {props.children}
    </Link>
  ) : (
    <div tabIndex={1}>
      {props.children}
      <div className="n-spacing-bottom-tiny n-spacing-top-small n-type-smallest n-color-accent">
        This item has no page
      </div>
    </div>
  )
