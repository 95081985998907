import React from "react"
import { Link, graphql } from "gatsby"

import { MHPageProps } from "../types"
import { PostListForMDX } from "../components/PostList"
import SEO from "../components/seo"

class MusicIndex extends React.Component<MHPageProps> {
  render() {
    const { data } = this.props

    const heading =
      "I've been casually making music for the last 10 years or so. Sometimes to imitate a style that I liked, sometimes to experiment, or sometimes just because I wanted to. Here's a few things I made"

    return (
      <>
        <SEO title="My music" description={heading} />
        <header className="oneColumn">
          <div className="n-spacing-bottom-medium">
            <h1 className="n-text-centered n-vspacing-small">My music</h1>
            <p>{heading}:</p>
          </div>
        </header>

        <PostListForMDX posts={data.allMdx.edges} />
      </>
    )
  }
}

export default MusicIndex

export const pageQuery = graphql`
  query ($publishedFilter: BooleanQueryOperatorInput) {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: {
        fields: { contentType: { eq: "music" } }
        frontmatter: { published: $publishedFilter }
      }
    ) {
      edges {
        node {
          ...MHExcerpt
          id
          fields {
            slug
            contentType
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            byline
            description
            published
            audio
          }
          wordCount {
            # For measuring if this page should have a permanent link
            words
          }
        }
      }
    }
  }
`
